.about_section {
  padding: 50px 0;
  background-color: #f6f3ef !important;
}



.about_heading_wrapper {
  text-align: center;
}

.about_heading_wrapper h2 {
  font-size: 40px;
  font-weight: 600;
  color: rgb(201, 60, 60);
}

.container-secnod{
  margin: 50px;
}

.about_heading_wrapper p {
  font-weight: 600;
  color: #503f2c;
}

.about_main_section {
  padding: 30px;
  /* background-color: #f6f3ef !important; */
}

.about_img_wrapper img {
  width: 100%;
}

.about_ul {
  list-style: none;
  padding-left: 0;
  margin-top: 25px;
}

.about_ul li {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1.5px solid #bc202046;
  margin-bottom: 10px;
}

.about_ul li .about_check_icon {
  color: #d7502e;
}

.about_ul li p {
  color: #4f3936;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding-left: 5px;
}

.about_right_p {
  color: #6c403b;
  font-size: 17px;
}

.about_eg_section {
  background-color: #f2ebe4;
  margin: 50px 0;
  padding: 50px;
  border-radius: 22px;
}

.about_eg_left h4 {
  color: #c14b2e;
  font-size: 25px;
  font-weight: 700;
}

.about_eg_left p {
  font-weight: 600;
  font-size: 19px;
}

.about_eg_img img {
  width: 100%;
}


@media (max-width: 600px) {
  .container-secnod{
    margin: 20px;
  }
  
}