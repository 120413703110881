.Site-Navigation {
     background-color: rgb(237, 91, 38);
     padding: 0;
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     transition: top 0.4s;
     width: 100%;
     
     padding: 15px 35px;
     
}

.nav-content {
     gap: 20px;
}

.PName {
     font-size: 2rem;
     margin: 1%;
     font-weight: 600;
     font-family: 'Devanagari Marathi', serif;
     letter-spacing: 0.5px;
}

.PName:hover {
     color: #fb8b24;

}

.nlink {
     font-weight: bold;
     font-size: 18px;
     font-family: 'Poppin', sans-serif;
     text-decoration: none;
     color: #ffff;
     font: 600;
     letter-spacing: 0.5px;
     cursor: pointer;
     text-align: center;
}

.nlink:hover {
     color: #fb8b24;
}

@media (max-width: 600px) {
     .Site-Navigation {
          padding: 0 10px;
          display: flex;
          justify-content: center;
          align-items: center;

     }

     .PName {
          font-size: 16px;
     }

     .site-mobile-view {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
     }

     .nav-content {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          width: 100%;
          padding: 30px 0;
     }



}