.prem-container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    max-width: 1500px;
    margin: auto;
    font-family: 'Arial', sans-serif;
    text-align: center; /* Center align the content */
  }
  
  .prem-container h1 {
    color: #d12613;
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .yantra-cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 20px;
  }
  
  .card {
    background-color: #f59b1d;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    width: 350px;
  }
  
  .card img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .card p {
    color: #e31111;
    font-size: 25px;
    margin: 0;
  }
  
  .issues-section {
    margin-top: 20px;
    background-color: #f59b1d;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center; /* Align text inside issues section to left */
    max-width: 700px;
    margin: auto; /* Center align the issues section */
  }
  
  .issues-section h2 {
    color: #1289ea;
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .issues-section ul {
    list-style-type: none; 
    padding: 0;
  }
  
  .issues-section ul li {
    background-color: #c9981c;
    border: 1px solid #10527d;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    
  }
  
  .issues-section p {
    color: #1280b7;
    font-size: 18px;
     font-weight: bold;
  }
  