@font-face {
  font-family: 'Devanagari Marathi', serif;
  src: url('./app/assets/font/DevanagariMarathi-Regular.ttf');
}

@font-face {
  font-family: 'Poppins', sans-serif;
  src: url('./app/assets/font/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins-Medium', sans-serif;
  src: url('./app/assets/font/Poppins-Medium.ttf');
}


* {
  margin: 0;
  padding: 0;
  transition: all .2s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subheader-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.subheader-details h4 {
  font-family: 'Devanagari Marathi', serif;
  font-weight: 600;
  letter-spacing: 0.5px;
  font-size: 28px;
  color: #712cf9;
  margin-top:20px ;
  text-align: center;
}

.subheader-details p {
  font-family: 'Devanagari Marathi', serif;
  font-size: 18px;
  display: inline-block;
  text-align: center;

  
}