.subheader-details p{
      margin-left: 20vw;
      margin-right: 20vw;
}
.container {
    position: relative;
    margin: 30px 0;
}

.container .card {
    position: relative;
    width: 270px;
    height: 430px;
    background: #232323;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
}

.stone-row {
    padding: 0 35px;
}

.container .card:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fb8b24;
    clip-path: circle(150px at 80% 20%);
    transition: 0.5s ease-in-out;
}

.container .card:hover:before {
    clip-path: circle(300px at 80% -20%);
}

.container .card:after {
    content: "रत्न";
    position: absolute;
    top: 30%;
    font-size: 12em;
    font-weight: 800;
    font-style: italic;
    color: rgba(255, 255, 255, 0.04);

}

.container .card .imgBx {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    z-index: 1000;
    width: 100%;
    height: 100%;
    transition: .5s;
}

.container .card:hover .imgBx {
    top: 0%;
    transform: translateY(-25%);
}

.container .card .imgBx img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%) rotate(20deg);
    width: 270px;
}

.container .card .contentBx {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    text-align: center;
    transition: 1s;
    z-index: 90;
}

.container .card:hover .contentBx {
    height: 260px;
}

.container .card .contentBx h2 {
    position: relative;
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
    font-size: 22px;
}

.container .card .contentBx .size,
.container .card .contentBx .color {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 20px;
    transition: .5s;
    opacity: 0;
    visibility: hidden;
}

.container .card:hover .contentBx .size {
    opacity: 1;
    visibility: visible;
    transition-delay: .5s;
}

.container .card:hover .contentBx .color {
    opacity: 1;
    visibility: visible;
    transition-delay: .6s;
}

.container .card .contentBx .size h3,
.container .card .contentBx .color h3 {
    color: white;
    font-weight: 300;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-right: 10px;
}

.container .card .contentBx .size span {
    width: 26px;
    height: 26px;
    text-align: center;
    line-height: 26px;
    font-size: 14px;
    display: inline-block;
    color: #111;
    background: #fff;
    margin: 0 5px;
    transition: .5s;
    color: #111;
    border-radius: 4px;
    cursor: pointer;
}

.container .card .contentBx .size span:hover {
    /* other bug */
    background: #B90000;
}

.container .card .contentBx .color span {
    width: 20px;
    height: 20px;
    background: #ff0;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
}

.container .card .contentBx .color span:nth-child(2) {
    background: #fb8b24;
}

.container .card .contentBx .color span:nth-child(3) {
    background: #1B2FE9;
}

.container .card .contentBx .color span:nth-child(4) {
    background: #080481;
}

.container .card .contentBx a {
    display: inline-block;
    padding: 5px 12px;
    background: #fff;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 600;
    color: #111;
    opacity: 0;
    transform: translateY(50px);
    transition: .5s;
}

.container .card:hover .contentBx a {
    opacity: 1;
    transform: translateY(0px);
    transition-delay: .7s;
}

.payment-section {
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 0 40px;
    margin-bottom: 30px;
}

.payment-row {
    border: 1.4px solid #1B2FE9;
    border-radius: 10px;
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.payment-col {
    display: flex;
    justify-content: center;
    align-items: center;
}


@media (max-width: 600px) {
    .stone-row {
        padding: 0 40px;
    }

    .qr-image {
        width: 100%;
        height: 100%;
    }
}