.service_main_section {
  padding: 10px 35px;
}

.service_section {
  padding: 30px 0 30px 0;
}
.subheader-details h4{
  color: rgb(204, 78, 32)!important;
  font-size: 2rem !important;
}
.service_heading_wrapper {
  text-align: center;
}

.service_heading_wrapper h2 {
  font-size: 40px;
  font-weight: 600;
  color: #fb8b24;
}

.service_heading_wrapper p {
  font-weight: 600;
  color: #36454f;
}

.service_card_wrapper {
  padding: 30px;
  border-radius: 7px;
  border: 1.5px solid #ccc;
  background-color: #FFECB3;
  margin-top: 20px;
  align-items: start;
  color: #fff;
  box-shadow: 0 10px 20px -15px #353535;
  transition: all 0.3s;
  cursor: pointer;
}

.service_card_wrapper:hover {
  box-shadow: 0 20px 20px -15px #353535;
}

.service_card_wrapper img {
  width: 50%;
  margin-right: 18px;
  float: left;
}

.service_card_wrapper div {
  padding-left: 10px;
}

.service_card_wrapper div h4 {
  color: #8d5c08;
  text-decoration: underline #cf5d0b;
  font-size: 22px;
  font-weight: bold;
}

.service_card_wrapper div p {
  font-size: 17px;
  color: #cf5d0b;
}

@media (max-width: 600px) {
  .service_card_wrapper img {
    width: 100%;
    margin-bottom: 40px;
  }

  .service_card_wrapper div {
    text-align: center;
  }
}