.home-container {
    background-image: linear-gradient(180deg, rgba(10, 0, 0, 0) 0%, rgba(0, 0, 10, 0.95) 100%), url('../assets/images/9bd9a5fc-23bf-4002-9581-9b11fcfa8f28_1130x550.webp') !important;
    width: 100%;
    height: 100vh;
    background-position: 50% -40%;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.home-content {
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.home-content h5 {
    font-size: 50px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #ffff;
    font-family: 'Devanagari Marathi', serif;
    padding-top: 250px;
    text-align: center;

}

.home-content h4 {
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #ffff;
    font-family: 'Devanagari Marathi', serif;

}


.button-62 {
    background: linear-gradient(to bottom right, #d10d31, #daa51f) !important;
    border: 0;
    border-radius: 8px;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 2.5;
    outline: transparent;
    padding: 0.2rem 1.5rem;
    text-align: center;
    text-decoration: none;
    transition: box-shadow .2s ease-in-out;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    margin: 10px;
}

.button-62:not([disabled]):focus {
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}

.button-62:not([disabled]):hover {
    box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}

.home-Why-section {
    width: 100%;
    height: 100%;
    background-color: #f4ede5;
}

.home-Why-section h4 {
    font-size: 30px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.5px;
    color: rgba(193, 56, 56, 0.894);
    text-align: center;
    padding-top: 50px;
}

.home-Why-section h5 {
    font: 27px;
    padding: 10px 0px;
    text-align: center;
    font-family: 'Devanagari Marathi', serif;
    padding-bottom: 30px;

}

.why-section-coloums {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid  rgba(193, 56, 56, 0.894);
    border-radius: 10px;
    width: 32%;
    padding: 20px 35px;


}

.why-section-row {
    padding: 0 35px;
    padding-bottom: 30px;
    gap: 15px;
}

.contents h6 {
    font-size: 19px;
    font-weight: bold;
    padding-top: 15px;
    font-family: 'Devanagari Marathi', serif;

}

@media (max-width: 600px) {
    .home-container {
        width: 100%;
        height: 100vh;
        margin-top:30px ;
        background-position: 4% 30%;
        
    }

    .home-content h5 {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: 0.4px;
        color: #ffff;
        font-family: 'Devanagari Marathi', serif;
        padding-top: 180px;
        text-align: center;
    }

    .home-content h4 {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.5px;
        color: #ffff;
        font-family: 'Devanagari Marathi', serif;

    }

    .home-Why-section h4 {
        font-size: 25px;
        font-weight: bold;
        font-family: 'Poppins', sans-serif;
        letter-spacing: 0.5px;
        color: #712cf9;
        text-align: center;
        width: 100%;
        padding-top: 40px;
    }

    .home-Why-section h5 {
        font: 25px;
        width: 100%;

    }

    .why-section-coloums {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 2px solid #712cf9;
        border-radius: 10px;
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        padding: 20px 35px;


    }

}