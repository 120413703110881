.contact_section {
  padding: 50px 0;
}

.contact_heading_wrapper {
  text-align: center;
}

.contact_heading_wrapper h2 {
  font-size: 3px;
  font-weight: 600;
  color: #c63f24;
}

.row-contact {
  display: flex;
  flex-direction: row;
  padding-left: 70px;
  gap: 20px;
}

.contact_heading_wrapper p {
  font-weight: 600;
  color: #36454f;
}

.contact_main_section {
  padding: 20px 5px;
}

.contact_box_wrapper {
  padding: 30px;
  background-color: #fff;
  border: 1.5px solid #ccc;
  box-shadow: 0 2px 6px #f0efef;
  border-radius: 20px;
  margin: 10px 0;
}

.contact_img_wrapper img {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
  padding: 10px;
  height: 31vw;
}

.contact_sub_part h4 {
  font-weight: 600;
}

.contact_right_part h3 {
  font-weight: 700;
  font-size: 30px;
  text-align: left;
  color: #d66616;
}

.contact_right_part p {
  font-size: 18px;
}

.contact_form_section {
  margin-top: 18px;
}

.contact_form_section div {
  display: grid;
}

.form_label {
  margin-top: 25px;
  margin-bottom: 4px;
  font-size: 18px;
}

.form_input {
  background-color: #e5e5e5;
  border-radius: 7px;
  display: block;
  width: 100%;
  padding: 10px 12px;
  background-clip: padding-box;
  border: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form_input::placeholder {
  font-size: 18px;
}

.form_input:focus {
  border: none;
  outline: none;
}

.submit_btn_wrapper {
  margin-top: 40px;
}

.submit_btn_wrapper .submit_btn {
  border-style: none;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #36454f;
  width: 100%;
  padding: 14px 0 15px;
  color: #fff;
  border-radius: 7px;
  font-size: 20px;

}


.button-62 {
  background: linear-gradient(to bottom right, #EF4765, #712cf9);
  border: 0;
  border-radius: 12px;
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 2.5;
  outline: transparent;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-62:not([disabled]):focus {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}

.button-62:not([disabled]):hover {
  box-shadow: 0 0 .25rem rgba(0, 0, 0, 0.5), -.125rem -.125rem 1rem rgba(239, 71, 101, 0.5), .125rem .125rem 1rem rgba(255, 154, 90, 0.5);
}

@media (max-width: 600px) {
  .row-contact {
    display: flex;
    flex-direction: column;
    padding-left: 0;

  }
}