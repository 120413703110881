.special-puja-row{
    padding:0 30px 40px 30px;
    margin-bottom: 50px;
}
.special-puja-col{
    height: 250px;
    margin-top: 20px;
}

.galleryimg{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    cursor: pointer;
    object-fit: cover;
}

.galleryimg:hover{
    box-shadow: 0px 2px 6px gray;
}